import { RouterProvider } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'

import { router } from './routes/routes'
import i18next from './intl/i18n'

import { ThemeProvider, createTheme } from '@mui/material'


function App() {

  const theme = createTheme({
    typography:{
      allVariants: {
        color: "#222222"
      },
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },    
  });

  return ( 
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      </ThemeProvider>
    </I18nextProvider>
  )
}

export default App