import { useState, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'

import aktuell from '../../assets/images/aktuell.webp'
import human from '../../assets/images/human.webp'
import manager from '../../assets/images/manager.webp'
import goethe from '../../assets/images/goethe.webp'
import getabstract from '../../assets/images/getabstract.webp'
import kultur from '../../assets/images/kultur.webp'

const Contacto = () => {
  const { t } = useTranslation() 

  const getCurrentWidth = ()=>{
    return window.innerWidth
  }
  const [screenWidth, setScreenWidth] = useState(getCurrentWidth())

  useLayoutEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth())
    }
    window.addEventListener('resize', updateDimension)

    return(() => {
        window.removeEventListener('resize', updateDimension)
    })
  }, [screenWidth])  

  return (
    <Box alignItems='center' justifyContent='center' sx={{display:'flex', flexDirection:'column', paddingTop:8, paddingBottom: 0, backgroundColor:'#F2F4EC'}}>      
      <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='center'>{t('contacto.titulo')}</Typography>
      <Box marginTop={4}>
        <Box onClick={()=> window.location.href = 'mailto:contact@annafuchs.info'} sx={{display:'flex', flexDirection:'row', cursor:'pointer', userSelect:'none'}}>
          <EmailOutlinedIcon sx={{fontSize:26, marginTop:0.5, marginRight:2, color: '#222222'}}/>
          <Typography textAlign='center' fontFamily='Aspira Wide W01 Regular' sx={{fontSize:20, marginRight:2, color: '#222222'}}>{'| '}</Typography>     
          <Typography fontSize={18} fontFamily='Aspira Wide W01 Regular' textAlign='center'>{t('contacto.email')}</Typography>     
        </Box>
        <Box onClick={()=> window.location.href = 'tel:+34666046678'} marginTop={4} sx={{display:'flex', flexDirection:'row', cursor:'pointer', userSelect:'none'}}>
          <LocalPhoneOutlinedIcon sx={{fontSize:24, marginTop:0.5, marginRight:2, color: '#222222'}}/>
          <Typography textAlign='center' fontFamily='Aspira Wide W01 Regular' sx={{fontSize:20, marginRight:2, color: '#222222'}}>{'| '}</Typography>     
          <Typography fontSize={18} fontFamily='Aspira Wide W01 Regular' textAlign='center'>{t('contacto.telefono')}</Typography>     
        </Box> 
      </Box>
      {
        screenWidth > 900 ?
        <Box flexWrap='wrap' justifyContent='center' alignItems={'center'} sx={{width: '100%', display:'flex', flexDirection:'row', paddingBottom: 2, backgroundColor: 'white'}} marginTop={7}>
          <Box onClick={()=> window.open('https://www.managerseminare.de/ta_Artikel/Kultursensibel-kommunizieren-Vielfalt-von-innen,283569', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={3} sx={{cursor: 'pointer'}}>
            <img loading='lazy' src={aktuell} alt='' width={'160'} />
          </Box>
          <Box onClick={()=> window.open('https://www.managerseminare.de/ms_Artikel/Transkulturelle-Kompetenz-entwickeln-Diverser-denken,283111', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={3} marginLeft={-10} sx={{cursor: 'pointer'}}>
            <img loading='lazy' src={manager} alt='' width={'200'} />
          </Box>
          <Box onClick={()=> window.open('https://www.humanresourcesmanager.de/personalmanagement/hr-interkulturelle-kommunikation-in-teams/', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={3} marginLeft={-8} sx={{cursor: 'pointer'}}>
            <img loading='lazy' src={human} alt='' width={'200'}/>
          </Box>
          <Box onClick={()=> window.open('https://www.goethe.de/de/spr/unt/for/kur/imp/imp.html?wt_sc=podcast-impulse', '_blank')} alignItems='center' justifyContent='center' width={150} marginTop={3} marginLeft={-6} sx={{cursor: 'pointer'}}>
            <img loading='lazy' src={goethe} alt='' width={'130'} />
          </Box>
          <Box onClick={()=> window.open('https://www.getabstract.com/de/zusammenfassung/transkulturelle-herausforderungen-meistern/45436', '_blank')} alignItems='center' justifyContent='center' width={150} marginTop={2} marginLeft={-2} sx={{cursor: 'pointer'}}>
            <img loading='lazy' src={getabstract} alt='' width={'150'} />
          </Box>
          <Box onClick={()=> window.open('https://www.deutschlandfunkkultur.de/durch-die-blume-rettende-duefte-und-glueckliche-blueten-ganze-sendung-dlf-kultur-96684040-100.html', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={2} marginLeft={1} sx={{cursor: 'pointer'}}>
            <img loading='lazy' src={kultur} alt='' width={'200'} />
          </Box>
        </Box> 
        : 
        <div style={{width: '100%', display:'flex', flexDirection:'column', marginTop: 64, paddingBottom: 24, paddingTop: 16, backgroundColor: 'white', justifyContent: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'row', textAlign: 'center', marginLeft: 8}}>
            <Box onClick={()=> window.open('https://www.managerseminare.de/ta_Artikel/Kultursensibel-kommunizieren-Vielfalt-von-innen,283569', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={3} sx={{cursor: 'pointer'}}>
              <img loading='lazy' src={aktuell} alt='' width={'120'} />
            </Box>
            <Box onClick={()=> window.open('https://www.managerseminare.de/ms_Artikel/Transkulturelle-Kompetenz-entwickeln-Diverser-denken,283111', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={2} marginLeft={-6} sx={{cursor: 'pointer'}}>
              <img loading='lazy' src={manager} alt='' width={'160'} />
            </Box>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', textAlign: 'center', marginLeft: 8}}>
            <Box onClick={()=> window.open('https://www.humanresourcesmanager.de/personalmanagement/hr-interkulturelle-kommunikation-in-teams/', '_blank')} alignItems='center' justifyContent='center' width={250} sx={{cursor: 'pointer'}}>
              <img loading='lazy' src={human} alt='' width={'160'}/>
            </Box>
            <Box onClick={()=> window.open('https://www.goethe.de/de/spr/unt/for/kur/imp/imp.html?wt_sc=podcast-impulse', '_blank')} alignItems='center' justifyContent='center' width={150} marginLeft={-9} sx={{cursor: 'pointer'}}>
              <img loading='lazy' src={goethe} alt='' width={'90'} />
            </Box>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', textAlign: 'center', marginLeft: 18}}>
            <Box onClick={()=> window.open('https://www.getabstract.com/de/zusammenfassung/transkulturelle-herausforderungen-meistern/45436', '_blank')} alignItems='center' justifyContent='center' width={150} marginTop={-1} sx={{cursor: 'pointer'}}>
              <img loading='lazy' src={getabstract} alt='' width={'110'} />
            </Box>
            <Box onClick={()=> window.open('https://www.deutschlandfunkkultur.de/durch-die-blume-rettende-duefte-und-glueckliche-blueten-ganze-sendung-dlf-kultur-96684040-100.html', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={1} marginLeft={-7} sx={{cursor: 'pointer'}}>
              <img loading='lazy' src={kultur} alt='' width={'160'} />
            </Box>
          </div>
        </div> 
      }
    </Box> 
  )
}

export default Contacto