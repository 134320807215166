import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

const CondicionesPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <div style={{display:'flex', paddingTop: 100, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={26} fontFamily={'Montserrat-Medium'} textAlign='center'>
          {t('pages.condiciones.titulo')}
        </Typography>    
        <Typography fontSize={21} fontFamily={'Montserrat-Light'} textAlign='left' marginTop={0.5}>
          {t('pages.condiciones.texto')}
        </Typography>    
      </div>

      <div style={{display:'flex', marginTop:30, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.condiciones.subtitulo1')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.condiciones.texto1')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:30, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.condiciones.subtitulo2')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.condiciones.texto2')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:30, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.condiciones.subtitulo3')}
        </Typography>
        <Typography fontSize={21} marginBottom={6} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.condiciones.texto3')}
        </Typography>
      </div>
    </>
  )
}

export default CondicionesPage