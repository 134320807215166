import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'

import logo from '../../assets/images/logo_anna.jpg'

const MenuTopBar = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const pages = [
    {nombre:t('menu.training'), ruta:'/training'},
    {nombre:t('menu.coaching'), ruta:'/coaching'},
    {nombre:t('menu.trans'), ruta:'/interandtranscultural'},
    {nombre:t('menu.referencias'), ruta:'/references'},
    {nombre:t('menu.contacto'), ruta:'contacto'}
  ]

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event:React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const clickLink = (link:string) => {
    if (link !== 'contacto') {
      navigate(link)
    } else {
      window.scrollTo({left:0, top:document.body.scrollHeight, behavior:'smooth'})
    }
    
    handleCloseNavMenu()
  }

  const changeLanguage = (lang:string) => {
    i18n.changeLanguage(lang)
  }  

  return (
    <AppBar position='sticky' sx={{backgroundColor:'white', position:'fixed', zIndex:2}} >
      <Container maxWidth={false} disableGutters>
        <Toolbar>
          <Box sx={{display:{xs:'none', md:'flex', cursor: 'pointer', height: 45}}} onClick={()=>navigate('/')}>
            <img src={logo} alt=''/>
          </Box> 
          <Box sx={{ flexGrow:1, display:{ xs:'flex', md:'none'}}}>
            <Box sx={{display:{xs:'flex', md:'none', cursor: 'pointer', height: 35}}} onClick={()=>navigate('/')}>
              <img src={logo} alt=''/>
            </Box> 
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              marginThreshold={0}              
              anchorOrigin={{
                vertical:'bottom',
                horizontal:'left',
              }}
              keepMounted
              transformOrigin={{
                vertical:'top',
                horizontal:'center',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display:{ xs:'block', md:'none'},
                '& .MuiMenu-paper':{ 
                  backgroundColor:'black', 
                  width:'100%',
                  maxWidth:'100%',
                  left:0,
                  right:0,
                } 
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.nombre} onClick={()=>clickLink(page.ruta)} sx={{justifyContent:'center', backgroundColor:'black'}}>
                  <Typography fontSize={15} textAlign='center' color='white' fontFamily='Aspira Wide W01 Regular'>{page.nombre}</Typography>
                </MenuItem>
              ))}
              <Box sx={{ display:{ xs:'flex', md:'none' }, marginTop:3, flexDirection:'row',justifyContent:'center', backgroundColor:'black'}}>
                <Button
                  onClick={()=>changeLanguage('de-DE')}
                  sx={{my:2}}
                >
                  <Typography fontSize={15} fontFamily='Aspira Wide W01 Regular' color={i18n.language === 'de-DE' ? '#007AFF' :'white'}>
                    {t('menu.deu')}
                  </Typography>
                </Button>
                <Typography sx={{ my:2.8, color:'white'}}>|</Typography>
                <Button
                  onClick={()=>changeLanguage('en-EN')}
                  sx={{my:2}}
                >
                  <Typography fontSize={15} fontFamily='Aspira Wide W01 Regular' color={i18n.language === 'en-EN' ? '#007AFF' :'white'}>
                    {t('menu.eng')}
                  </Typography>
                </Button>
                <Typography sx={{ my:2.8, color:'white'}}>|</Typography>
                <Button
                  onClick={()=>changeLanguage('es-ES')}
                  sx={{my:2}}
                >
                  <Typography fontSize={15} fontFamily='Aspira Wide W01 Regular' color={i18n.language === 'es-ES' ? '#007AFF' :'white'}>                      
                    {t('menu.esp')}
                  </Typography>
                </Button>
              </Box> 
            </Menu>
            <Box sx={{ flexGrow:1, display:{ xs:'flex', md:'none' }, justifyContent:'flex-end' }}>
              <IconButton
                size='large'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'            
              >
                {
                  anchorElNav ?
                  <CloseIcon sx={{color:'black', marginRight: -1}}/>
                  :
                  <MenuIcon sx={{color:'black', marginRight: -1}}/>
                }              
              </IconButton>
            </Box>
          </Box>                 
          <Box sx={{ flexGrow:1, display:{ xs:'none', md:'flex' }, justifyContent:'center' }}>
            {pages.map((page) => (
              <Button
                key={page.nombre}
                onClick={()=>clickLink(page.ruta)}
                sx={{ my:2, mr:2, color:'#222222', display:'block', '&:hover':{backgroundColor:'transparent', color:'black', textDecoration:'underline'}}}
              >
                <Typography fontSize={13} fontFamily='Aspira Wide W01 Regular' fontWeight={'bold'} >
                  {page.nombre}
                </Typography>
              </Button>
            ))}
          </Box>
          <Box sx={{display:{ xs:'none', md:'flex'}, flexDirection:'row', flexGrow:0}}>
            <Button
              onClick={()=>changeLanguage('de-DE')}
              sx={{ my:2, '&:hover':i18n.language !== 'de-DE' ? {backgroundColor:'transparent', color:'black', textDecoration:'underline' } :{backgroundColor:'transparent'}}}
            >
              <Typography fontSize={12} fontFamily='Aspira Wide W01 Regular' color={i18n.language === 'de-DE' ? 'blue' :'#222222'} fontWeight={'bold'}>
                {t('menu.deu')}
              </Typography>
            </Button>
            <Typography sx={{ my:2.8, color:'#222222'}} fontFamily='Aspira Wide W01 Regular'>|</Typography>
            <Button
              onClick={()=>changeLanguage('en-EN')}
              sx={{ my:2, '&:hover':i18n.language !== 'en-EN' ? {backgroundColor:'transparent', color:'black', textDecoration:'underline' } :{backgroundColor:'transparent'}}}
            >
              <Typography fontSize={12} fontFamily='Aspira Wide W01 Regular' color={i18n.language === 'en-EN' ? 'blue' :'#222222'} fontWeight={'bold'}>
                {t('menu.eng')}
              </Typography>              
            </Button>
            <Typography sx={{ my:2.8, color:'#222222'}} fontFamily='Aspira Wide W01 Regular'>|</Typography>
            <Button
              onClick={()=>changeLanguage('es-ES')}
              sx={{ my:2, '&:hover':i18n.language !== 'es-ES' ? {backgroundColor:'transparent', color:'black', textDecoration:'underline' } :{backgroundColor:'transparent'}}}
            >
              <Typography fontSize={12} fontFamily='Aspira Wide W01 Regular' color={i18n.language === 'es-ES' ? 'blue' :'#222222'} fontWeight={'bold'}>
                {t('menu.esp')}
              </Typography>          
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default MenuTopBar