import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import anna from '../../assets/images/home_parallax.webp'
import Dibujos from '../../components/dibujos/Dibujos'
import Carrusel from '../../components/carrusel/Carrusel'

const HomePage = () => {
  const { t } = useTranslation()
 
  const getCurrentWidth = ()=>{
    return window.innerWidth
  }
  const [screenWidth, setScreenWidth] = useState(getCurrentWidth())

  useLayoutEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth())
    }
    window.addEventListener('resize', updateDimension)

    return(() => {
        window.removeEventListener('resize', updateDimension)
    })
  }, [screenWidth])  

  return (
    <>
      <img src={anna} alt='' width={screenWidth-15} height={screenWidth / 1.8} style={{marginTop:screenWidth > 900 ? 68 :55}} />
      <div style={{textAlign:'center', position:'absolute', left:0, right:0, top:screenWidth > 900 ? screenWidth / 2.8 : screenWidth / 2.5, bottom:0, zIndex:1}}>
        <h2 style={{fontFamily: 'Volte-Medium', color:'#313131', textAlign:'center', fontSize:screenWidth > 800 ? 30 :22}}>{t('pages.home.titulo')}</h2>     
        <h1 style={{fontFamily: 'Boldstrom', fontSize:screenWidth > 900 ? 120 : 50, lineHeight: 0.9, textAlign:'center', opacity: 0.5, marginTop: -28, whiteSpace:screenWidth > 1300 ? 'pre-line':'normal'}}>{t('pages.home.anna')}</h1>
      </div>
      <div style={{display:'flex', marginTop:screenWidth > 900 ? 40 : 20, marginBottom:30, marginLeft:20, marginRight:20, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontFamily={'Montserrat-Medium'} fontSize={21} textAlign='center' marginTop={2}>
          {t('pages.home.tituloHola')}
        </Typography>
        <Typography fontFamily={'Montserrat-Regular'} fontSize={20} textAlign='center' marginTop={4}>
          {t('pages.home.textoHola')}
        </Typography>
      </div>
      <Dibujos />
      <Carrusel />      
    </>
  )
}

export default HomePage