import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Typography } from '@mui/material'
import CookieConsent from 'react-cookie-consent'

const CookieAlert: React.FC = () => {
  const { t } = useTranslation()  
  const navigate = useNavigate() 

  return (
    <CookieConsent
      location='bottom'
      buttonText={t('cookies.acceptButton')}     
      declineButtonText={t('cookies.rejectButton')}
      enableDeclineButton
      setDeclineCookie
      flipButtons
      overlay
      hideOnAccept
      hideOnDecline
      cookieName='af-cookies-v1'
      declineCookieValue='reject-cookie'
      style={{background: '#2B373B', userSelect: 'none'}}
      buttonStyle={{color: '#4e503b', fontSize: 15, marginLeft: 40, marginBottom: 40, fontFamily:'Montserrat-Medium'}}
      declineButtonStyle={{backgroundColor: 'grey', color: 'white', fontSize: 15, marginRight: 40, marginBottom: 40, fontFamily:'Montserrat-Medium'}}
      expires={180}
      contentStyle={{padding: 24}}
      visible='byCookieValue'
    >      
      <Typography fontSize={17} fontFamily='Montserrat-Light' textAlign='left' color={'white'} >
        <Trans
          i18nKey='cookies.texto'
          components={{
            linkPrivacy: <span onClick={()=> navigate('/privacy')} onKeyDown={()=>{}} style={{cursor: 'pointer', color: 'yellow', fontFamily:'Montserrat-Medium'}}/>,
          }}
        />
      </Typography>          
    </CookieConsent> 
  )
}

export default CookieAlert