import { createBrowserRouter } from 'react-router-dom'

import LayoutPage from '../pages/layout/LayoutPage'
import HomePage from '../pages/home/HomePage'
import TrainingPage from '../pages/training/TrainingPage'
import CoachingPage from '../pages/coaching/CoachingPage'
import TransculturalPage from '../pages/transcultural/TransculturalPage'
import ReferenciasPage from '../pages/referencias/ReferenciasPage'
import CondicionesPage from '../pages/condiciones/CondicionesPage'
import PoliticaPage from '../pages/politica/PoliticaPage'
import PrensaPage from '../pages/prensa/PrensaPage'

export const router = createBrowserRouter([
    { 
        path: '/',
        element: <LayoutPage />,
        children: [
            {
                path: '/',
                element: <HomePage />
            },                       
            {
                path: 'training',
                element: <TrainingPage />
            },                       
            {
                path: 'coaching',
                element: <CoachingPage />
            },                       
            {
                path: 'interandtranscultural',
                element: <TransculturalPage />
            },                       
            {
                path: 'references',
                element: <ReferenciasPage />
            },                       
            {
                path: 'terms',
                element: <CondicionesPage />
            },                       
            {
                path: 'privacy',
                element: <PoliticaPage />
            },                       
            {
                path: 'press',
                element: <PrensaPage />
            },                       
        ],
    },    
])