import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import yellow from '../../assets/images/yellow.webp'
import home from '../../assets/images/home.webp'
import rama from '../../assets/images/rama.webp'

const Fotos = () => {
  const { t } = useTranslation() 
  const navigate = useNavigate()

  return (
    <Box flexWrap='wrap' justifyContent='center' sx={{display:'flex', flexDirection:'row', paddingLeft: 4, paddingRight: 4}}>
      <Box alignItems='center' justifyContent='center' width={250} paddingLeft={3} paddingRight={3} marginTop={4}>
        <img loading='lazy' src={yellow} alt='' width={250} height={250}/>
        <Typography fontSize={18} fontFamily={'Montserrat-Medium'} textAlign='center' >{t('pages.prensa.epigrafe1')}</Typography>
      </Box>
      <Box alignItems='center' justifyContent='center' width={250} paddingLeft={3} paddingRight={3} marginTop={4}>
        <img loading='lazy' src={home} alt='' width={250} height={250}/>
        <Typography fontSize={18} fontFamily={'Montserrat-Medium'} textAlign='center'>{t('pages.prensa.epigrafe2')}</Typography>
      </Box>
      <Box alignItems='center' justifyContent='center' width={250} paddingLeft={3} paddingRight={3} marginTop={4}>
        <img loading='lazy' src={rama} alt='' width={250} height={250}/>
        <Typography fontSize={18} fontFamily={'Montserrat-Medium'} textAlign='center'>{t('pages.prensa.epigrafe3')}</Typography>
      </Box>
    </Box>    
  )
}

export default Fotos