import { useLayoutEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Box, Typography } from '@mui/material'

import aktuell from '../../assets/images/aktuell.webp'
import human from '../../assets/images/human.webp'
import manager from '../../assets/images/manager.webp'
import goethe from '../../assets/images/goethe.webp'
import getabstract from '../../assets/images/getabstract.webp'
import kultur from '../../assets/images/kultur.webp'
import logo from '../../assets/images/logo_anna.jpg'

import Fotos from '../../components/Prensa/Fotos'


const PrensaPage = () => {
  const { t } = useTranslation()
 
  const getCurrentWidth = ()=>{
    return window.innerWidth
  }
  const [screenWidth, setScreenWidth] = useState(getCurrentWidth())

  useLayoutEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth())
    }
    window.addEventListener('resize', updateDimension)

    return(() => {
        window.removeEventListener('resize', updateDimension)
    })
  }, [screenWidth])  

  return (
    <> 
      <div style={{display:'flex', paddingTop: 100, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={24} fontFamily={'Montserrat-Medium'} textAlign='center'>
          {t('pages.prensa.titulo')}
        </Typography>        
      </div>

      <Typography marginLeft={3} fontFamily={'Montserrat-Medium'} fontSize={21} marginTop={4}>
        {t('pages.prensa.subtitulo1')}
      </Typography>
      <Typography marginLeft={3} fontFamily={'Montserrat-Light'} fontSize={20} marginTop={4}>          
        <Trans
          i18nKey='pages.prensa.texto1'
          components={{
            linkEmail: <a href='mailto:contact@annafuchs.info' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
            linkPhone: <a href='tel:+34666046678' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,             
          }}
        />
      </Typography>

      <Box flexWrap='wrap' justifyContent='center' alignItems={'center'} sx={{display:'flex', flexDirection:{ xs:'column', md:'row' }}} marginTop={2} marginLeft={{ xs:6, md:0 }}>
        <Box onClick={()=> window.open('https://www.managerseminare.de/ta_Artikel/Kultursensibel-kommunizieren-Vielfalt-von-innen,283569', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={3} sx={{cursor: 'pointer'}}>
          <img loading='lazy' src={aktuell} alt='' width={'200'} />
        </Box>
        <Box onClick={()=> window.open('https://www.managerseminare.de/ms_Artikel/Transkulturelle-Kompetenz-entwickeln-Diverser-denken,283111', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={3} marginLeft={{ xs:-2, md:-7 }} sx={{cursor: 'pointer'}}>
          <img loading='lazy' src={manager} alt='' width={'250'} />
        </Box>
        <Box onClick={()=> window.open('https://www.humanresourcesmanager.de/personalmanagement/hr-interkulturelle-kommunikation-in-teams/', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={3} marginLeft={-5} sx={{cursor: 'pointer'}}>
          <img loading='lazy' src={human} alt='' width={'250'}/>
        </Box>
        <Box onClick={()=> window.open('https://www.goethe.de/de/spr/unt/for/kur/imp/imp.html?wt_sc=podcast-impulse', '_blank')} alignItems='center' justifyContent='center' width={150} marginTop={3} marginLeft={{ xs:-14, md:-1 }} sx={{cursor: 'pointer'}}>
          <img loading='lazy' src={goethe} alt='' width={'200'} />
        </Box>
        <Box onClick={()=> window.open('https://www.getabstract.com/de/zusammenfassung/transkulturelle-herausforderungen-meistern/45436', '_blank')} alignItems='center' justifyContent='center' width={150} marginTop={1} marginLeft={{ xs:-13, md:5 }} sx={{cursor: 'pointer'}}>
          <img loading='lazy' src={getabstract} alt='' width={'200'} />
        </Box>
        <Box onClick={()=> window.open('https://www.deutschlandfunkkultur.de/durch-die-blume-rettende-duefte-und-glueckliche-blueten-ganze-sendung-dlf-kultur-96684040-100.html', '_blank')} alignItems='center' justifyContent='center' width={250} marginTop={1} marginLeft={{ xs:-6, md:6 }} sx={{cursor: 'pointer'}}>
          <img loading='lazy' src={kultur} alt='' width={'250'} />
        </Box>
      </Box>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.prensa.subtitulo2')}
        </Typography>
        <Typography fontSize={21} fontFamily={'Montserrat-Light'} textAlign='left' marginTop={2}>          
          <Trans
            i18nKey='pages.prensa.texto2'
            components={{
              linkLibro: <a href='https://www.amazon.de/Transkulturelle-Herausforderungen-meistern-Missverst%C3%A4ndnisse-Kompetenzen/dp/3499000636/' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
            }}
          />
        </Typography>
      </div>
      
      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={20} fontFamily={'Montserrat-Medium'}>
          {t('pages.prensa.subtitulo3')}
        </Typography>
        <Typography fontSize={20} fontFamily={'Montserrat-Light'} marginTop={3}>
          {t('pages.prensa.texto3')}
        </Typography>
      </div>

      <div style={{display:'flex', paddingTop: 60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line', alignItems:'center'}}>
        <Typography fontSize={24} fontFamily={'Montserrat-Medium'} textAlign='center'>
          {t('pages.prensa.tituloBrand')}
        </Typography>        
        <Typography fontSize={24} fontFamily={'Montserrat-Medium'} textAlign='center' marginTop={2}>
          {t('pages.prensa.tiuloLogo')}
        </Typography> 
        <img loading='lazy' src={logo} alt='' width={'200'} style={{marginTop: 30}} />       
      </div>

      <div style={{display:'flex', paddingTop: 60, marginLeft:25, marginRight:25, marginBottom: 50, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={24} fontFamily={'Montserrat-Medium'}>
          {t('pages.prensa.photo')}
        </Typography>
        <Fotos />  
      </div>  
    </>
  )
}

export default PrensaPage