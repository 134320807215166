import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import anna from '../../assets/images/coaching_parallax.webp'

const CoachingPage = () => {
  const { t } = useTranslation()

  const getCurrentWidth = ()=>{
    return window.innerWidth
  }
  const [screenWidth, setScreenWidth] = useState(getCurrentWidth())

  useLayoutEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth())
    }
    window.addEventListener('resize', updateDimension)

    return(() => {
        window.removeEventListener('resize', updateDimension)
    })
  }, [screenWidth])  

  return (
    <>
      <img src={anna} alt='' width={screenWidth-15} height={screenWidth / 1.8} style={{marginTop:screenWidth > 900 ? 68 :55}} />
      <div style={{textAlign:'center', position:'absolute', left:0, right:0, top:screenWidth / 2.6, bottom:0, zIndex:1}}>
        <h1 style={{fontFamily: 'Boldstrom', fontSize:screenWidth > 900 ? 100 : 50, textAlign:'center', opacity:0.5, whiteSpace:screenWidth > 1300 ? 'pre-line':'normal'}}>{t('pages.coaching.titulo')}</h1>
      </div>
      
      <div style={{display:'flex', marginTop:30, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.coaching.subtitulo1')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.coaching.texto1')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.coaching.subtitulo2')}
        </Typography>
        <Typography marginLeft={3} fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.coaching.texto2')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.coaching.subtitulo3')}
        </Typography>
        <Typography marginLeft={3} fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.coaching.texto3')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.coaching.subtitulo4')}
        </Typography>
        <Typography marginLeft={3} fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.coaching.texto4')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={20} fontFamily={'Montserrat-Medium'} textAlign='center'>
          {t('pages.coaching.tituloEpigrafe')}
        </Typography>
        <Typography fontSize={20} fontFamily={'Montserrat-Light'} marginBottom={6} textAlign='center'>
          {t('pages.coaching.textoEpigrafe')}
        </Typography>
      </div>
    </>
  )
}

export default CoachingPage