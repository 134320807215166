import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

//import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationDE from './locales/de.json'
import translationEN from './locales/en.json'
import translationES from './locales/es.json'

i18n 
  //.use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    /*fallbackLng:()=>{
      console.log(localStorage.getItem('i18nextLng'));
      if (localStorage.getItem('i18nextLng') === null) {
        return 'de-DE'
      }
      return ''
    },*/
    debug: true,
    interpolation: {
      escapeValue: false
    },
    //lng: 'de-DE',
    resources: {
      de: {
        translation: translationDE
      },
      en:{
        translation: translationEN
      },
      es: {
        translation: translationES
      },     
    }
  })

export default i18n