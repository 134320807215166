import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import linkedin from '../../assets/icons/linkedin.svg'
import xing from '../../assets/icons/xing.svg'

const Footer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate() 

  return (
    <Box alignItems='center' justifyContent='center' sx={{display:'flex', flexDirection:'column', padding:2, backgroundColor:'black', whiteSpace:'pre-line'}}>      
      <Typography onClick={()=> navigate('/terms')} sx={{cursor: 'pointer'}} fontSize={10} fontFamily='Aspira Wide W01 Regular' textAlign='center' color={'white'}>{t('footer.condiciones')}</Typography>
      <Typography onClick={()=> navigate('/privacy')} sx={{cursor: 'pointer'}} fontSize={10} fontFamily='Aspira Wide W01 Regular' textAlign='center' color={'white'} marginTop={1}>{t('footer.politica')}</Typography>
      <Typography onClick={()=> navigate('/press')} sx={{cursor: 'pointer'}} fontSize={10} fontFamily='Aspira Wide W01 Regular' textAlign='center' color={'white'} marginTop={2}>{t('footer.prensa')}</Typography>
      <Box marginTop={1} sx={{display:'flex', flexDirection:'row', width:{xs: '97.2vw', md: '99.1vw'}, justifyContent: 'center'}}>        
        <Box onClick={()=> window.open('https://www.linkedin.com/in/annafuchs/', '_blank')} alignItems={'center'} sx={{display:'flex', flexDirection:'column', marginLeft:10, marginRight:10, cursor:'pointer', userSelect:'none'}}>
          <img src={linkedin} width={40} height={40} alt='' color='white'/>
          <Typography textAlign='center' fontFamily='Montserrat-Medium' color={'#818A91'} sx={{fontSize:15, marginTop:1}}>{'LINKEDIN'}</Typography> 
        </Box>
        <Box onClick={()=> window.open('https://www.xing.com/profile/Anna_Fuchs15', '_blank')}  alignItems={'center'} sx={{display:'flex', flexDirection:'column', marginLeft:10, marginRight:10, cursor:'pointer', userSelect:'none'}}>
          <img src={xing} width={40} height={40} alt='' color='white'/>
          <Typography textAlign='center' fontFamily='Montserrat-Medium' color={'#818A91'} sx={{fontSize:15, marginTop:1}}>{'XING'}</Typography> 
        </Box>         
      </Box>   
      <Typography onClick={()=> navigate('/privacy')} sx={{cursor: 'pointer'}} fontSize={10} textAlign='center' color={'#818A91'} marginTop={1} marginBottom={2}>{t('footer.copyright')}</Typography>  
      <Box onClick={()=> window.scrollTo({left:0, top:0, behavior:'smooth'})} width={32} height={32} bgcolor={'#818A91'} alignSelf={'flex-end'} marginTop={-6} marginRight={2} marginBottom={2} textAlign={'center'}>
        <KeyboardArrowUpIcon sx={{color:'white', textAlign:'center', marginTop:0.6}}/>
      </Box>
    </Box>    
  )
}

export default Footer