import { Outlet } from 'react-router-dom'

import MenuTopBar from '../../components/menu/MenuTopBar'
import Contacto from '../../components/contacto/Contacto'
import Footer from '../../components/footer/Footer'
import ScrollToTop from '../../utils/scrollToTop'
import CookieAlert from '../../components/cookies/CookieAlert'

const LayoutPage = () => {
  return (
    <div>
      <CookieAlert />          
      <MenuTopBar />
      <ScrollToTop />
      <Outlet />
      <Contacto /> 
      <Footer />
    </div>
  )
}

export default LayoutPage