import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import anna from '../../assets/images/trans_parallax.webp'

const TransculturalPage = () => {
  const { t } = useTranslation()

  const getCurrentWidth = ()=>{
    return window.innerWidth
  }
  const [screenWidth, setScreenWidth] = useState(getCurrentWidth())

  useLayoutEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth())
    }
    window.addEventListener('resize', updateDimension)

    return(() => {
        window.removeEventListener('resize', updateDimension)
    })
  }, [screenWidth])  

  return (
    <>
      <img src={anna} alt='' width={screenWidth-15} height={screenWidth / 1.8} style={{marginTop:screenWidth > 900 ? 68 :55}} />      
      <div style={{display:'flex', marginTop:30, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={26} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.trans.titulo')}
        </Typography>        
      </div>
      
      <div style={{display:'flex', marginTop:30, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.trans.subtitulo1')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.trans.texto1')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.trans.subtitulo2')}
        </Typography>
        <Typography marginLeft={3} fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.trans.texto2')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.trans.subtitulo3')}
        </Typography>
        <Typography marginLeft={3} fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.trans.texto3')}
        </Typography>
      </div> 

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.trans.subtitulo4')}
        </Typography>
        <Typography marginLeft={3} fontSize={21} marginBottom={6} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.trans.texto4')}
        </Typography>
      </div>     
    </>
  )
}

export default TransculturalPage