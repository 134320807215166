import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import training from '../../assets/images/training_dibujo.webp'
import coaching from '../../assets/images/coaching_dibujo.webp'
import intercultural from '../../assets/images/intercultural_dibujo.webp'

const Dibujos = () => {
  const { t } = useTranslation() 
  const navigate = useNavigate()

  return (
    <Box flexWrap='wrap' justifyContent='center' sx={{display:'flex', flexDirection:'row', padding:8}}>
      <Box onClick={()=>navigate('/training')} alignItems='center' justifyContent='center' width={300} paddingLeft={3} paddingRight={3} sx={{cursor:'pointer', paddingTop:3}}>
        <img loading='lazy' src={training} alt='' width={300} />
        <Typography fontSize={24} fontFamily={'Montserrat-Medium'} textAlign='center' marginTop={-2}>{t('dibujos.epigrafeTraining')}</Typography>
      </Box>
      <Box onClick={()=>navigate('/coaching')} alignItems='center' justifyContent='center' width={300} paddingLeft={3} paddingRight={3} sx={{cursor:'pointer', paddingTop:3}}>
        <img loading='lazy' src={coaching} alt='' width={300} />
        <Typography fontSize={24} fontFamily={'Montserrat-Medium'} textAlign='center' marginTop={-2}>{t('dibujos.epigrafeCoaching')}</Typography>
      </Box>
      <Box onClick={()=>navigate('/interandtranscultural')} alignItems='center' justifyContent='center' width={300} paddingLeft={3} paddingRight={3} sx={{cursor:'pointer', paddingTop:3}}>
        <img loading='lazy' src={intercultural} alt='' width={300} />
        <Typography fontSize={24} fontFamily={'Montserrat-Medium'} textAlign='center' marginTop={-2}>{t('dibujos.epigrafeTrans')}</Typography>
      </Box>
    </Box>    
  )
}

export default Dibujos