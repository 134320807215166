import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Carousel } from '@trendyol-js/react-carousel'

import libro from '../../assets/images/libro_trans.webp'

const Carrusel = () => {
  const { t } = useTranslation() 

  return (
    <Box alignItems='center' justifyContent='center' sx={{display:'flex', flexDirection:{ xs:'column', md:'row' }, padding:3, backgroundColor:'#515050', whiteSpace:'pre-line'}}>      
      <Box onClick={()=> window.open('https://www.amazon.de/Transkulturelle-Herausforderungen-meistern-Missverst%C3%A4ndnisse-Kompetenzen/dp/3499000636/', '_blank')} sx={{display:{ xs: 'flex', md: 'none' }, marginBottom:4}}>
        <img src={libro} width={206} height={300} alt=''/>
      </Box>
      <Box sx={{width:{xs: '90%', md: '50%'}, alignContent: 'center', justifyContent: 'center'}}>
        <Carousel      
          show={1}
          slide={1}
          transition={1}
          autoSwipe={3000}
          leftArrow={<div>{''}</div>}
          rightArrow={<div>{''}</div>}
        >      
          <Box sx={{display:'flex', flexDirection:'column', alignItems: 'center'}}> 
            <Typography fontSize={35} textAlign='center' fontFamily='Nunito' color={'white'}>{t('carrusel.texto1')}</Typography> 
            <Typography fontSize={14} textAlign='center' fontFamily='Aspira Wide W01 Regular' marginTop={3} color={'white'}>{t('carrusel.autor1')}</Typography> 
            <Typography fontSize={11} textAlign='center' marginTop={2} fontFamily='Montserrat-Medium' color={'white'}>{' '}</Typography>            
          </Box> 
          <Box sx={{display:'flex', flexDirection:'column'}}> 
            <Typography fontSize={35} textAlign='center' fontFamily='Nunito' color={'white'}>{t('carrusel.texto2')}</Typography> 
            <Typography fontSize={14} textAlign='center' fontFamily='Aspira Wide W01 Regular' marginTop={3} color={'white'}>{t('carrusel.autor2')}</Typography> 
            <Typography fontSize={11} textAlign='center' marginTop={2} fontFamily='Montserrat-Medium' color={'white'}>{t('carrusel.fuente2')}</Typography>
          </Box> 
          <Box sx={{display:'flex', flexDirection:'column'}}> 
            <Typography fontSize={35} textAlign='center' fontFamily='Nunito' color={'white'}>{t('carrusel.texto3')}</Typography> 
            <Typography fontSize={14} textAlign='center' fontFamily='Aspira Wide W01 Regular' marginTop={3} color={'white'}>{t('carrusel.autor3')}</Typography> 
            <Typography fontSize={11} textAlign='center' marginTop={2} fontFamily='Montserrat-Medium' color={'white'}>{t('carrusel.fuente3')}</Typography>
          </Box> 
          <Box sx={{display:'flex', flexDirection:'column'}}> 
            <Typography fontSize={35} textAlign='center' fontFamily='Nunito' color={'white'}>{t('carrusel.texto4')}</Typography> 
            <Typography fontSize={14} textAlign='center' fontFamily='Aspira Wide W01 Regular' marginTop={3} color={'white'}>{t('carrusel.autor4')}</Typography> 
            <Typography fontSize={11} textAlign='center' marginTop={2} fontFamily='Montserrat-Medium' color={'white'}>{t('carrusel.fuente4')}</Typography>
          </Box> 
          <Box sx={{display:'flex', flexDirection:'column'}}> 
            <Typography fontSize={35} textAlign='center' fontFamily='Nunito' color={'white'}>{t('carrusel.texto5')}</Typography> 
            <Typography fontSize={14} textAlign='center' fontFamily='Aspira Wide W01 Regular' marginTop={3} color={'white'}>{t('carrusel.autor5')}</Typography> 
            <Typography fontSize={11} textAlign='center' marginTop={2} fontFamily='Montserrat-Medium' color={'white'}>{t('carrusel.fuente5')}</Typography>
          </Box> 
          <Box sx={{display:'flex', flexDirection:'column'}}> 
            <Typography fontSize={35} textAlign='center' fontFamily='Nunito' color={'white'}>{t('carrusel.texto6')}</Typography> 
            <Typography fontSize={14} textAlign='center' fontFamily='Aspira Wide W01 Regular' marginTop={3} color={'white'}>{t('carrusel.autor6')}</Typography> 
            <Typography fontSize={11} textAlign='center' marginTop={2} fontFamily='Montserrat-Medium' color={'white'}>{t('carrusel.fuente6')}</Typography>
          </Box> 
        </Carousel>
      </Box>    
      <Box sx={{display:{ xs: 'none', md: 'flex' }}}>
        <img src={libro} width={206} height={300} alt=''/>
      </Box>
    </Box>    
  )
}

export default Carrusel