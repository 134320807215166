import { useLayoutEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import anna from '../../assets/images/home_parallax.webp'

const ReferenciasPage = () => {
  const { t } = useTranslation()

  const getCurrentWidth = ()=>{
    return window.innerWidth
  }
  const [screenWidth, setScreenWidth] = useState(getCurrentWidth())

  useLayoutEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth())
    }
    window.addEventListener('resize', updateDimension)

    return(() => {
        window.removeEventListener('resize', updateDimension)
    })
  }, [screenWidth])  

  return (
    <>
      <img src={anna} alt='' width={screenWidth-15} height={screenWidth / 1.8} style={{marginTop:screenWidth > 900 ? 68 :55}} /> 
      <div style={{textAlign:'center', position:'absolute', left:0, right:0,  top:screenWidth / 2.6, bottom:0, zIndex:1}}>
        <h1 style={{fontFamily: 'Boldstrom', fontSize:screenWidth > 900 ? 100 : 50, textAlign:'center', opacity:0.6, whiteSpace:screenWidth > 1300 ? 'pre-line':'normal'}}>{t('pages.referencias.titulo')}</h1>
      </div>   
      <div style={{display:'flex', marginTop:30, marginLeft:20, marginRight:20, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={26} fontFamily={'Montserrat-Medium'} textAlign='center'>
          {t('pages.referencias.reTitulo')}
        </Typography>        
      </div>

      <div style={{display:'flex', marginTop:30, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.referencias.subtitulo1')}
        </Typography>
        <Typography fontSize={21} marginLeft={3} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.referencias.texto1')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.referencias.subtitulo2')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.referencias.texto2')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.referencias.subtitulo3')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          <Trans
            i18nKey='pages.referencias.texto3'
            components={{
              linkLibro: <a href='https://www.amazon.de/Transkulturelle-Herausforderungen-meistern-Missverst%C3%A4ndnisse-Kompetenzen/dp/3499000636/' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link1: <a href='https://www.managerseminare.de/ta_Artikel/Kultursensibel-kommunizieren-Vielfalt-von-innen,283569' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link2: <a href='https://www.managerseminare.de/ms_Artikel/Transkulturelle-Kompetenz-entwickeln-Diverser-denken,283111' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link3: <a href='https://www.managerseminare.de/ms_Artikel/Tutorial-Transkulturelle-Kompetenz-entwickeln,283098' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link4: <a href='https://www.humanresourcesmanager.de/personalmanagement/hr-interkulturelle-kommunikation-in-teams/' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link5: <a href='https://www.amazon.de/El-arte-conversar-psicolog%C3%ADa-comunicaci%C3%B3n/dp/8425428696/' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link6: <a href='https://www.amazon.de/Interkulturalit%C3%A4t-internationalen-Entwicklungszusammenarbeit-Internationalen-Interdisziplin%C3%A4re/dp/3866188048/' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
            }}
          />
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.referencias.subtitulo4')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          <Trans
            i18nKey='pages.referencias.texto4'
            components={{
              link1: <a href='https://kulturshaker.de/praxisbuch-fuer-transkulturelle-herausforderungen/' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link2: <a href='https://www.inntal-institut.de/blog/transkulturelle-herausforderungen-meistern' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link3: <a href='https://www.getabstract.com/de/summary/45436' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
            }}
          />
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.referencias.subtitulo5')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          <Trans
            i18nKey='pages.referencias.texto5'
            components={{
              linkCurso: <a href='https://www.linkedin.com/learning/interkulturelle-kommunikation-kultursensible-gesprache-fuhren-18795193' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,              
            }}
          />
        </Typography>
      </div>  

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.referencias.subtitulo6')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          <Trans
            i18nKey='pages.referencias.texto6'
            components={{
              link1: <a href='https://www.goethe.de/de/spr/unt/for/kur/imp/imp.html?wt_sc=podcast-impulse' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link2: <a href='https://www.goethe.de/de/spr/unt/for/kur/imp/imp.html?wt_sc=podcast-impulse' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link3: <a href='https://open.spotify.com/episode/32qWMICMsh3SC9aocUZ9lC?si=ssk8M8gTQbeHF3N7hkVGSQ&nd=1' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link4: <a href='https://www.deutschlandfunkkultur.de/durch-die-blume-rettende-duefte-und-glueckliche-blueten-ganze-sendung-dlf-kultur-96684040-100.html' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link5: <a href='https://open.spotify.com/episode/3nQ8LWW6fScyeBptn5AiVg?si=UWcLfkCmT7m4LTZTRR250w' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link6: <a href='https://open.spotify.com/episode/4kWxq7L0u48OwV4gmfjMJK' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link7: <a href='https://tiny.one/spotf' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link8: <a href='https://lnkd.in/gsJpYW_U' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link9: <a href='https://open.spotify.com/episode/2AxJ87g7yIxNOAWMI7oFvv' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link10: <a href='https://lnkd.in/d_jfkySu' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link11: <a href='https://coaching-to-go.space/podcast-nr-101-transkulturelle-herausforderungen-meistern/' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link12: <a href='https://www.kalaidos-fh.ch/de-CH/Blog/Posts/2022/03/Leadership-1014-Warum-wir-kommunizieren-wie-wir-kommunizieren' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,
              link13: <a href='https://open.spotify.com/episode/17yqmIiUDPQzxIasgihlLn' target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'black'}}> </a>,  
            }}
          />
        </Typography>
      </div>   

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.referencias.subtitulo7')}
        </Typography>
        <Typography fontSize={21} marginBottom={6} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.referencias.texto7')}
        </Typography>
      </div>     
    </>
  )
}

export default ReferenciasPage