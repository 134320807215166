import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

const PoliticaPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <div style={{display:'flex', paddingTop: 100, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={26} fontFamily={'Montserrat-Medium'} textAlign='center'>
          {t('pages.politica.titulo')}
        </Typography>        
      </div>

      <div style={{display:'flex', marginTop:30, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloResponsable')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoResponsable')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloInformacion')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoInformacion')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloComoUsamos')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoComoUsamos')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloAlojamiento')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoAlojamiento')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloCooperacion')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoCooperacion')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloEliminacion')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoEliminacion')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloPresencia')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoPresencia')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloBoletines')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoBoletines')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloBlogs')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoBlogs')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloEnlaces')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoEnlaces')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloUsoCookies')}
        </Typography>
        <Typography fontSize={21} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoUsoCookies')}
        </Typography>
      </div>

      <div style={{display:'flex', marginTop:60, marginLeft:25, marginRight:25, flexDirection:'column', whiteSpace:'pre-line'}}>
        <Typography fontSize={21} fontFamily={'Montserrat-Medium'} textAlign='left'>
          {t('pages.politica.subtituloRevocacion')}
        </Typography>
        <Typography fontSize={21} marginBottom={6} textAlign='left' fontFamily={'Montserrat-Light'} marginTop={2}>
          {t('pages.politica.textoRevocacion')}
        </Typography>
      </div>
    </>
  )
}

export default PoliticaPage